.appBar-app {
  border-radius: 15px;
  margin: 30px 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.heading-app {
  color: rgba(0, 183, 255, 1);
}

.image-app {
  margin-left: 15px;
}